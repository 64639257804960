// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/


@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono&family=Source+Sans+3:wght@400;600;700&display=swap');

*{
  font-family: 'Source Sans 3',-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  letter-spacing: 0px;
  // font-family: 'Roboto Mono', monospace;
}

ion-button{
  --border-radius: 4px;
  height: 32px;
  margin: 0px 6px;
  --padding: 6px 16px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}
ion-item::part(native) {
  --background: var(--grey-25);
}
// ............ table common start ................
.ant-dropdown{
  background: var(--grey-0);
  border: 1px solid var(--grey-100);
  border-radius: 4px;
  box-shadow: 0px 1px 4px 2px rgba(26, 26, 26, 0.05);
}
.ant-dropdown-menu-item{
  width: 137px;
  height: 36px;
  font-weight: 600;
  background: var(--grey-0);
}
.red_fc{
  color: var(--red-500);
}
.red_bg{
  background-color: var(--red-100);
  color: var(--red-500);
}
.table{
  padding: 24px 24px;
  nz-table{
    border: 1px solid var(--grey-100);
    border-radius: 4px;
    min-width: 776px;
    .ant-table{
      color: var(--grey-500);
    }
    .chip{
      padding: 0px 4px;
      border-radius: 4px;
      background: var(--blue-200);
      ion-label{
        text-transform: capitalize;
        color: var(--grey-900);
      }
    }
    .ant-table-cell{
      height: 52px;
      padding: 10px 16px;
    }
    .ant-table-thead{
      th{
        background-color: var(--grey-0);
        color: var(--grey-500);
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
      }
      tr{
        color: var(--grey-500);
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
  ion-icon[name= 'ellipsis-vertical']{
    color: var(--grey-500);
  }
  mat-paginator{
    padding-top: 8px;
    min-width: 776px;
    .mat-paginator-navigation-previous{
      background-color: var(--grey-0);
    }
    .mat-paginator-navigation-next{
      background-color: var(--grey-0);
    }
    .mat-paginator-navigation-previous:hover{
      cursor: pointer;
      background-color: var(--grey-25);
    }
    .mat-paginator-navigation-next:hover{
      cursor: pointer;
      background-color: var(--grey-25);
    }
    .mat-button-disabled{
      cursor: pointer;
      color: var(--grey-300)
    }
    .mat-button-disabled:hover{
      cursor: not-allowed;
    }
  }
}
// ............ table common end ................

// ............ toolbar common start ................
.booking_toolbar{
  padding: 24px 8px;
  padding-bottom: 0px;
  padding-right: 16px;
}

.toolbar_title{
  color: var(--grey-900);
  span{
    margin: 0px 8px;
  }
}
.top_title{
  color: var(--grey-900);
}
.toolbar_search{
  --background: var(--grey-50);
  --border-radius: 4px;
  --placeholder-color: var(--grey-500);
  --color: var(--grey-900);
  width: 200px;
  text-align: left;
position: relative;
height: 32px;
top: 8px;
}
.toolbar_filter_btn{
  ion-icon{
    padding-right: 8px;
  }
}

.header-md::after {
  background-image: none; //ionic specific top navbar shadow removal
  border-bottom: 1px solid var(--grey-100);
}
// ............ toolbar common end ................
// ............ input common start ................
.mat_input{
  ion-label{
    padding: 0px;
  }
  ion-input{
    --padding-bottom: 0px;
    --padding-top: 0px;
  }
}
/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
border: none;
-webkit-text-fill-color: var(--ion-color-secondary-contrast);
-webkit-box-shadow: none;
box-shadow: none;
transition: background-color 5000s ease-in-out 0s;
}
input::selection {
  background: var(--ion-color-primary-contrast); /* WebKit/Blink Browsers */
}
input::-moz-selection {
  background: var(--ion-color-primary-contrast); /* Gecko Browsers */
}
// ............ input common end ................

// ............ empty state common start ................
.empty_state_container{
  width: 100%;
  height: 60vh;
  align-items: center;
  transform: translateY(30%);
  ion-icon{
    width: 96px;
    height: 96px;
  }
  .share_icon{
    cursor: pointer;
    margin: 4px;
    width: 40px;
    height: 40px;
  }
  .empty_state{
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    width: 320px;
  }
}
// ............ empty state common end ................

.time_input{
  margin-right: 0%;
}
.input_item{
  ion-label{
    --color: var(--grey-500);
  }
  

  span{
  background-color: var(--grey-25);
  }
  --background: var(--grey-25);
  // border: 1px solid var(--grey-100);
  border-bottom: none;
  border-radius: 6px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
.country_code{
  width: 50px;
  --inner-padding-top: 0px;
  --inner-padding-bottom: 0px;
  --inner-padding-start: 0px;
  --inner-padding-end: 0px;
}
.country_code::part(native){
  width: 50px;
padding: 0%;
}
.country_code:hover{
  span{
    background-color: var(--grey-50);
  }
}

.pad_cancel_phone::part(native){
  padding: 0%;
  padding-left: 16px;
  padding-right: 0px;
}
.phone_no_fix{
  margin: 0%;
  width: 100%;
  border: none;
  border-radius: none;
}

.ta-left{
  text-align: left;
}
.ta-right{
  text-align: right;
}
.ta-center{
  text-align: center;
}

.black{
  color: var(--grey-900);
}
.grey{
  color: var(--grey-500);
}

.fw24-700{
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
}
.fw20-600{
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
}
.fw16-600{
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}
.fw16-400{
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
}
.fw14-600{
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}
.fw14-400{
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
}
.fw12-400{
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
}
.fw12-600{
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
}


/** Ionic CSS Variables **/
:root {
  /** blue **/
  --blue-100: #F0F6FF;
  --blue-200: #C5D9FB;
  --blue-300: #7D9EF8;
  --blue-400: #4D7BF4;
  --blue-500: #1A54F2;
  --blue-600: #0C40D0;
  --blue-700: #0932A4;

  /** green **/
  --green-100: #ECF8ED;
  --green-200: #BFE8C2;
  --green-300: #99DB9E;
  --green-400: #73CE79;
  --green-500: #4EC155;
  --green-600: #39A240;
  --green-700: #00832D;

  /** red **/
  --red-100: #FEE7E9;
  --red-200: #FAADB4;
  --red-300: #F77D88;
  --red-400: #F54D5C;
  --red-500: #F21C30;
  --red-600: #CF0C1E;
  --red-700: #A40A18;

  /** yellow **/
  --yellow-100: #FEF8E6;
  --yellow-200: #FDE7AA;
  --yellow-300: #FCD978;
  --yellow-400: #FBCB46;
  --yellow-500: #FABD14;
  --yellow-600: #D79F04;
  --yellow-700: #AA7D03;
  
  /** grey **/
  --grey-0: #FFFFFF;
  --grey-25: #F8F8F8;
  --grey-50: #F1F1F1;
  --grey-100: #E1E1E1;
  --grey-200: #C8C8C8;
  --grey-300: #ACACAC;
  --grey-400: #919191;
  --grey-500: #6E6E6E;
  --grey-600: #404040;
  --grey-700: #303030;
  --grey-800: #292929;
  --grey-900: #212121;
  --grey-950: #141414;
  --grey-1000: #000000;


  /** primary **/
  --ion-color-primary: var(--blue-500);
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: var(--grey-0);
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: var(--blue-300);
  --ion-color-primary-tint: var(--blue-600);

  /** secondary **/
  --ion-color-secondary: var(--grey-50);
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: var(--grey-900);
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: var(--grey-100);
  --ion-color-secondary-tint: var(--grey-100);

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: var(--green-500);
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
}

// @media (prefers-color-scheme: dark) {
//   /*
//    * Dark Colors
//    * -------------------------------------------
//    */

//   body {
//     --ion-color-primary: #428cff;
//     --ion-color-primary-rgb: 66,140,255;
//     --ion-color-primary-contrast: #ffffff;
//     --ion-color-primary-contrast-rgb: 255,255,255;
//     --ion-color-primary-shade: #3a7be0;
//     --ion-color-primary-tint: #5598ff;

//     --ion-color-secondary: #50c8ff;
//     --ion-color-secondary-rgb: 80,200,255;
//     --ion-color-secondary-contrast: #ffffff;
//     --ion-color-secondary-contrast-rgb: 255,255,255;
//     --ion-color-secondary-shade: #46b0e0;
//     --ion-color-secondary-tint: #62ceff;

//     --ion-color-tertiary: #6a64ff;
//     --ion-color-tertiary-rgb: 106,100,255;
//     --ion-color-tertiary-contrast: #ffffff;
//     --ion-color-tertiary-contrast-rgb: 255,255,255;
//     --ion-color-tertiary-shade: #5d58e0;
//     --ion-color-tertiary-tint: #7974ff;

//     --ion-color-success: #2fdf75;
//     --ion-color-success-rgb: 47,223,117;
//     --ion-color-success-contrast: #000000;
//     --ion-color-success-contrast-rgb: 0,0,0;
//     --ion-color-success-shade: #29c467;
//     --ion-color-success-tint: #44e283;

//     --ion-color-warning: #ffd534;
//     --ion-color-warning-rgb: 255,213,52;
//     --ion-color-warning-contrast: #000000;
//     --ion-color-warning-contrast-rgb: 0,0,0;
//     --ion-color-warning-shade: #e0bb2e;
//     --ion-color-warning-tint: #ffd948;

//     --ion-color-danger: #ff4961;
//     --ion-color-danger-rgb: 255,73,97;
//     --ion-color-danger-contrast: #ffffff;
//     --ion-color-danger-contrast-rgb: 255,255,255;
//     --ion-color-danger-shade: #e04055;
//     --ion-color-danger-tint: #ff5b71;

//     --ion-color-dark: #f4f5f8;
//     --ion-color-dark-rgb: 244,245,248;
//     --ion-color-dark-contrast: #000000;
//     --ion-color-dark-contrast-rgb: 0,0,0;
//     --ion-color-dark-shade: #d7d8da;
//     --ion-color-dark-tint: #f5f6f9;

//     --ion-color-medium: #989aa2;
//     --ion-color-medium-rgb: 152,154,162;
//     --ion-color-medium-contrast: #000000;
//     --ion-color-medium-contrast-rgb: 0,0,0;
//     --ion-color-medium-shade: #86888f;
//     --ion-color-medium-tint: #a2a4ab;

//     --ion-color-light: #222428;
//     --ion-color-light-rgb: 34,36,40;
//     --ion-color-light-contrast: #ffffff;
//     --ion-color-light-contrast-rgb: 255,255,255;
//     --ion-color-light-shade: #1e2023;
//     --ion-color-light-tint: #383a3e;
//   }

//   /*
//    * iOS Dark Theme
//    * -------------------------------------------
//    */

//   .ios body {
//     --ion-background-color: #000000;
//     --ion-background-color-rgb: 0,0,0;

//     --ion-text-color: #ffffff;
//     --ion-text-color-rgb: 255,255,255;

//     --ion-color-step-50: #0d0d0d;
//     --ion-color-step-100: #1a1a1a;
//     --ion-color-step-150: #262626;
//     --ion-color-step-200: #333333;
//     --ion-color-step-250: #404040;
//     --ion-color-step-300: #4d4d4d;
//     --ion-color-step-350: #595959;
//     --ion-color-step-400: #666666;
//     --ion-color-step-450: #737373;
//     --ion-color-step-500: #808080;
//     --ion-color-step-550: #8c8c8c;
//     --ion-color-step-600: #999999;
//     --ion-color-step-650: #a6a6a6;
//     --ion-color-step-700: #b3b3b3;
//     --ion-color-step-750: #bfbfbf;
//     --ion-color-step-800: #cccccc;
//     --ion-color-step-850: #d9d9d9;
//     --ion-color-step-900: #e6e6e6;
//     --ion-color-step-950: #f2f2f2;

//     --ion-item-background: #000000;

//     --ion-card-background: #1c1c1d;
//   }

//   .ios ion-modal {
//     --ion-background-color: var(--ion-color-step-100);
//     --ion-toolbar-background: var(--ion-color-step-150);
//     --ion-toolbar-border-color: var(--ion-color-step-250);
//   }


//   /*
//    * Material Design Dark Theme
//    * -------------------------------------------
//    */

//   .md body {
//     --ion-background-color: #121212;
//     --ion-background-color-rgb: 18,18,18;

//     --ion-text-color: #ffffff;
//     --ion-text-color-rgb: 255,255,255;

//     --ion-border-color: #222222;

//     --ion-color-step-50: #1e1e1e;
//     --ion-color-step-100: #2a2a2a;
//     --ion-color-step-150: #363636;
//     --ion-color-step-200: #414141;
//     --ion-color-step-250: #4d4d4d;
//     --ion-color-step-300: #595959;
//     --ion-color-step-350: #656565;
//     --ion-color-step-400: #717171;
//     --ion-color-step-450: #7d7d7d;
//     --ion-color-step-500: #898989;
//     --ion-color-step-550: #949494;
//     --ion-color-step-600: #a0a0a0;
//     --ion-color-step-650: #acacac;
//     --ion-color-step-700: #b8b8b8;
//     --ion-color-step-750: #c4c4c4;
//     --ion-color-step-800: #d0d0d0;
//     --ion-color-step-850: #dbdbdb;
//     --ion-color-step-900: #e7e7e7;
//     --ion-color-step-950: #f3f3f3;

//     --ion-item-background: #1e1e1e;

//     --ion-toolbar-background: #1f1f1f;

//     --ion-tab-bar-background: #1f1f1f;

//     --ion-card-background: #1e1e1e;
//   }
// }
